import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import { getOmProxyHost } from '@otavamedia/om-component-library/lib/lib/utils'
// import {getOptimizeValue} from '@otavamedia/om-component-library/lib/lib/utils'
// import {getOptimizeValue} from '@otavamedia/om-component-library/lib/lib/utils'
// import MostReadPaidList from './MostReadPaidList'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { Link } from '../general/util/Links'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { SubscriberIcon } from './Icons'

import './Paywall.scss'

@connect({
  props: [
    auth, [
      'loggedIn',
    ],
  ],
})
class Paywall extends Component {
  state = {
    showAdCoin: false
  }

  static propTypes = {
    titleChildren: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    disableAdCoin: PropTypes.bool
  }

  static defaultProps = {
    titleChildren: [
      <h2 key="2">Tilaa {window.om_constants.siteName} ja saat rajattoman lukuoikeuden digipalveluun.</h2>,
    ]
  }

  async componentDidMount () {
    const script = document.createElement('script')
    script.setAttribute('id', 'paywall-script')
    script.src = '/assets/paywall-ab-testing.js?rm'
    script.async = true
    const paywallRoot = document.getElementById('paywall-root')
    window.paywallHost = window.om_constants.paywallHost
    window.paywallBrandId = window.om_constants.paywallBrandId
    window.offerId = window.location.host.includes(window.om_constants.paywallHost)
      ? window.om_constants.offerId
      : window.om_constants.offerIdDev
    window.paywallApiUrl = getOmProxyHost()
    window.paywallForeignCountryOrderLink = '/tilaa/?_ga=2.251339845.214372254.1555067838-765224471.1553754874'
    window.mocid = window.location.href
    const oldPWScript = document.querySelector('#paywall-script')
    if (oldPWScript) {
      document.body.removeChild(oldPWScript)
    }
    paywallRoot.appendChild(script)

    const addScript = document.createElement('script')
    // addScript.defer = true
    addScript.setAttribute('id', 'adcoin-script')
    addScript.setAttribute('src', process.env.ADCOIN_URL || 'https://adserve.adcoin.com/display/snippet/23/128/')
    const oldScript = document.querySelector('#adcoin-script')
    if (oldScript) {
      document.body.removeChild(oldScript)
    }
    document.body.appendChild(addScript)
    this.adCoinWatcher()
  }

  adCoinWatcher () {
    const that = this
    const interval = window.setInterval(function () {
      // If the Adcoin ad space code <script> snippet rendered an ad, then show
      // a short intro text above the ad
      if (document.getElementById('adcoinUnlockButton') && document.getElementById('adcoinUnlockButton').style.display !== 'none') {
        window.clearInterval(interval)
        that.setState({ showAdCoin: true })
        window.dataLayer && window.dataLayer.push({
          event: 'adCoinVisible',
          adCoinVisible: true
        })
      }
    }, 1000)

    // Don't run the interval forever. Give an Adcoin ad time to load.
    window.setTimeout(function () {
      window.clearInterval(interval)
    }, 20000)
  }

  render () {
    const { showAdCoin } = this.state
    const { disableAdCoin } = this.props
    const loginAction = () => {
      this.actions.updateModal({ open: true })
    }

    return <Fragment>
      {!disableAdCoin && <div styleName={'adcoin-block' + (showAdCoin ? ' adcoin-available' : '')}>
        {showAdCoin && <div>
          <h3 styleName="adcoin-title">Lue koko juttu ilmaiseksi!</h3>
          <p styleName="adcoin-desc">Voit lukea koko artikkelin ilmaiseksi katsomalla mainoksen</p>
        </div>}
        <div id="adcoin_unlock_button"></div>
        <div id="adcoin_ad_element"></div>
      </div>}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div styleName="top">
        <SubscriberIcon/>
        <a styleName="login-button" href={WP.getLoginUrl()}>
          <span>Kirjaudu</span>
        </a>
      </div>

      <div styleName="wrapper" onKeyDown={(e) => e.stopPropagation()} role="presentation"
           onTouchStart={(e) => e.stopPropagation()}
           onTouchMove={(e) => e.stopPropagation()}
           onMouseDown={(e) => e.stopPropagation()}>
        <div styleName="title new-ab">
          <h2 key="2">Mitä saat tilaajana</h2>
          <ul>
            <li>Testipankissa yli 200 hyödyllistä testiä ja vertailua</li>
            <li>Näköislehtiarkistossa kaikki lehdet vuodesta 2003 alkaen</li>
            <li>Vinkit-osiossa käytännöllisiä ohjeita, oppaita ja videoita niin nikkaroitiin kuin jokapäiväiseen asumiseenkin</li>
          </ul>
        </div>
        {/* <MostReadPaidList/> */}
        <div id="paywall-root" styleName="paywall-ab" />
      </div>
    </Fragment>
  }
}

export default Paywall
